<template>
  <b-modal body-class="p-0" id="modal-config-webhooks" modal-class="modal-webhooks" hide-header hide-footer>
    <div class="modal-webhooks__close d-flex justify-content-end w-100">
			<IconSax
				name="close-circle"
				color="var(--fontcolor)"
				@click="cancel"
			/>
		</div>

    <div class="modal-webhooks__header">
      <TextPlay size="lg" color="var(--fontcolor)" weight="bold" :text="$t('settings.webhooks.modal_title')" />
      <TextPlay color="var(--text-color)" :text="$t('settings.webhooks.modal_desc')" />
    </div>

    <div class="modal-webhooks__documentation">
      <TextPlay @click="openDocumentacaoWebHook" size="sm" color="var(--maincolor)" :text="$t('settings.webhooks.documentation')" />
      <IconSax @click="openDocumentacaoWebHook" size="20px" name="document-text" primary />
    </div>

    <div class="modal-webhooks__form">
      <b-form novalidate @submit.prevent="adicionarWebhook">
        <div class="modal-webhooks__form__check-group">
          <b-form-group label-for="check">
            <b-form-checkbox 
              size="md"
              switch
              class="check-item"
              v-for="(op, index) in events.options_sales"
              :name="`check-${index}`"
              :id="`check-${index}`"
              v-model="selectEvent[index]"
              :key="op.value"
              @change="triggerChanged(op.value)"
            >
              <TextPlay size="sm" :text="op.text" />

              <div class="check-item__sub-list" v-show="op.value == 'inactivityTime' && selectEvent[1]" data-anima="top">
                <b-form-checkbox
                  size="md"
                  switch v-for="(sub, index) in events.options_inactivityTime"
                  :name="`check-inactive-${index}`"
                  :id="`check-inactive-${index}`"
                  :key="sub.value"
                  v-model="selectEventInactive[index]"
                  @change="subTriggerChanged(sub.value, 'inactivityTime')"
                >
                  <TextPlay size="sm" :text="sub.text" />
                </b-form-checkbox>
              </div>

              <div class="check-item__sub-list" v-show="op.value == 'courseProgress' && selectEvent[5]" data-anima="top">
                <b-form-checkbox
                  size="md"
                  switch
                  v-for="(sub, index) in events.options_courseProgress"
                  :name="`check-waiting-${index}`"
                  :id="`check-waiting-${index}`"
                  :key="sub.value"
                  v-model="selectEventWaiting[index]"
                  @change="subTriggerChanged(sub.value, 'courseProgress')"
                >
                  <TextPlay size="sm" :text="sub.text" />
                </b-form-checkbox>
              </div>
            </b-form-checkbox>
          </b-form-group>
        </div>

        <b-form-group :label=" $t('settings.webhooks.send_to_url')" label-for="url_webhook">
          <b-form-input
            v-validate="{ url: { require_protocol: true } }"
            name="url_webhook" id="url_webhook"
            :placeholder="$t('settings.webhooks.webhook_placeholder')"
            type="text"
            v-model="events.url"
            autocomplete="off"
            @v-on:keyup.enter="adicionarWebhook"
          />
          <b-form-invalid-feedback :state="!errors.has('url_webhook')">
            {{ $t('settings.webhooks.invalid_format') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </div>

    <div class="modal-webhooks__actions">
      <ButtonPlay @click="cancel" type="cancel" :text="$t('settings.webhooks.cancel')" />
      <ButtonPlay @click="adicionarWebhook" type="normal" :text="$t('settings.webhooks.save')" />
    </div>
  </b-modal>
</template>
  
<script>
// services:
import CallbackService from "@/services/resources/CallbackService";
import { mapActions } from "vuex";
const callbackService = CallbackService.build();
const eventsInitialState = {
  trigger: [],
  sub_trigger: [],
  sub_trigger2: [],
  url: "",
  callback: "executarWebhook",
  options_sales: [
    {
      value: "classEndDate",
      text: "Data de Conclusão de Aula",
    },
    {
      value: "inactivityTime",
      text: "Tempo de Inatividade",
    },
    {
      value: "moduleEndDate",
      text: "Data de Conclusão do Módulo",
    },
    {
      value: "courseEndDate",
      text: "Data de Conclusão do Curso",
    },
    {
      value: "certificateDate",
      text: "Emissão do Certificado",
    },
    {
      value: "courseProgress",
      text: "Progresso do Curso",
    },
  ],
  options_inactivityTime: [
    {
      value: "1DayOfInactivity",
      text: "1 Dia",
    },
    {
      value: "5DaysOfInactivity",
      text: "5 Dias",
    },
    {
      value: "10DaysOfInactivity",
      text: "10 Dias",
    },
    {
      value: "20DaysOfInactivity",
      text: "20 Dia",
    },
    {
      value: "30DaysOfInactivity",
      text: "30 Dias",
    },
    {
      value: "60DaysOfInactivity",
      text: "60 Dias",
    },
    {
      value: "90DaysOfInactivity",
      text: "90 Dias",
    },
  ],
  options_courseProgress: [
    {
      value: "25Progress",
      text: "Concluiu 25%",
    },
    {
      value: "50Progress",
      text: "Concluiu 50%",
    },
    {
      value: "75Progress",
      text: "Concluiu 75%",
    },
    {
      value: "100Progress",
      text: "Concluiu 100%",
    }
  ]
}
export default {
  data() {
    return {
      loading: false,
      submit: false,
      webhook: {
        user_id: "22",
        type: "webhook",
        object: [],
      },
      selectEvent: [],
      selectEventInactive: [],
      selectEventWaiting: [],
      init_click: false,
      events: eventsInitialState
    };
  },
  mounted() {
    this.events.options_sales.forEach(option => {
      option.text = this.$t(`settings.webhooks.${option.value}`);
    });

    this.events.options_inactivityTime.forEach(option => {
      option.text = this.$t(`settings.webhooks.${option.value}`);
    });

    this.events.options_courseProgress.forEach(option => {
      option.text = this.$t(`settings.webhooks.${option.value}`);
    });
  },
  methods: {
    ...mapActions({
      fetchWebhooks: "webhooks/fetchWebhooks",
    }),
    cancel() {
      this.$bvModal.hide("modal-config-webhooks");
    },
    triggerChanged(v) {
      const include = this.events.trigger.includes(v);

      if (v == "inactivityTime") {
        if (!include) {
          document.querySelector(`#check-inactive-0`).click();
        } else {
          this.events.options_inactivityTime.forEach((_, index) => {
            if (this.selectEventInactive[index]) {
              document.querySelector(`#check-inactive-${index}`).click();
            }
          });
        }
      } else if (v == "courseProgress") {
        if (!include) {
          document.querySelector(`#check-waiting-0`).click();
        } else {
          this.events.options_courseProgress.forEach((_, index) => {
            if (this.selectEventWaiting[index]) {
              document.querySelector(`#check-waiting-${index}`).click();
            }
          });
        }
      }

      if (include) {
        this.events.trigger.splice(this.events.trigger.indexOf(v), 1);
      } else {
        this.events.trigger.push(v);
      }
    },
    subTriggerChanged(v, type) {
      if (type === "inactivityTime") {
        const include = this.events.sub_trigger.includes(v);

        if (include) {
          this.events.sub_trigger.splice(this.events.sub_trigger.indexOf(v), 1);
        } else {
          this.events.sub_trigger.push(v);
        }
      } else {
        const include = this.events.sub_trigger2.includes(v);

        if (include) {
          this.events.sub_trigger2.splice(
            this.events.sub_trigger2.indexOf(v),
            1
          );
        } else {
          this.events.sub_trigger2.push(v);
        }
      }
    },
    openDocumentacaoWebHook() {
      window.open("https://www.voompcreators.com.br/termos-e-documentacoes/webhook-play/");
    },
    resetWebHook() {
      this.webhook = {
        user_id: "",
        type: "webhook",
        object: [],
      };
      this.events = eventsInitialState
      this.events.trigger = [];
      this.events.sub_trigger = [];
      this.events.sub_trigger2 = [];
    },
    async createWebhook(webhook) {
      let data = webhook;

      await callbackService.create(data);
      await this.fetchWebhooks();
      this.submit = false;
      this.loading = false;
    },
    async adicionarWebhook(e) {
      e?.preventDefault();
      this.submit = true;
      if (this.events.trigger.length && this.events.url) {
        this.$validator.validate("url_webhook").then((result) => {
          if (result) {
            this.loading = true;
            document.querySelector("#url_webhook").classList.remove("erro");
            [...this.events.trigger].forEach((item) => {
              if (item !== "inactivityTime" && item !== "courseProgress")
                this.webhook.object.push({
                  trigger: item,
                  type: "webhook",
                  callback: "executarWebhook",
                  url: this.events.url,
                });
            });

            if (this.events.trigger.includes("inactivityTime") && this.events.sub_trigger.length) {
              [...this.events.sub_trigger].forEach((item) => {
                this.webhook.object.push({
                  trigger: "inactivityTime",
                  type: "webhook",
                  sub_trigger: item,
                  callback: "executarWebhook",
                  url: this.events.url,
                });
              });
            }

            if (this.events.trigger.includes("courseProgress") && this.events.sub_trigger2.length) {
              [...this.events.sub_trigger2].forEach((item) => {
                this.webhook.object.push({
                  trigger: "courseProgress",
                  type: "webhook",
                  sub_trigger: item,
                  callback: "executarWebhook",
                  url: this.events.url,
                });
              });
            }

            this.webhook.object.forEach(async (item) => {
              await this.createWebhook(item);
            });

            this.resetWebHook();
            this.selectEvent = [];
            this.selectEventInactive = [];
            this.selectEventWaiting = [];
            this.loading = false;
            this.submit = false;

            this.$bvToast.toast('Webhook adicionado com sucesso', {
              title: "Webhook",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });

            this.$bvModal.hide("modal-config-webhooks");
          } else {
            if (document.querySelector("#url_webhook")) {
              document.querySelector("#url_webhook").classList.add("erro");
              document.querySelector("#url_webhook").focus();
            }
            this.$bvToast.toast('Erro ao adicionar webhook(s)', {
              title: "Webhook",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        });
      } else {
        this.$bvToast.toast('Verifique se todos os campos foram preenchidos corretamente', {
          title: "Webhook",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.modal-webhooks {
	.modal-dialog {
		width: 800px !important;
		max-width: 800px;
	}

	.modal-content {
		padding: 16px 24px 16px 24px !important;
	}
}

@media (max-width: 768px) {
	.modal-webhooks {
		.modal-dialog {
			margin: auto;
			width: 95% !important;
			max-width: 95% !important;
		}
	}
}
</style>

<style lang="scss" scoped>
.modal-webhooks {
  &__close {
    .isax {
      cursor: pointer;
    }
  }

  &__header {
    margin-bottom: 20px;
  }

  &__documentation {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 24px;

    p, .isax {
      cursor: pointer;
    }
  }

  &__form {
    &__check-group {
      .check-item {
        padding-bottom: 16px;
        padding-top: 16px;
        border-bottom: 1px solid var(--divider-color);

        &:nth-last-child(1) {
          border-bottom: none;
        }

        &__sub-list {
          display: flex;
          flex-direction: column;
          gap: 14px;
          margin-top: 16px;
        }
      }
    }

    #url_webhook {
      border: 1px var(--input-border-color) solid;
      border-radius: 8px;
      height: 48px;
      width: 100%;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 24px;
  }
}
</style>
  