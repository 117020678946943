<template>
	<div class="plans">
		<div
			class="plans__plan"
			v-for="(plan, index) in plans"
			:key="index"
		>
			<Plan
				:name="plan.name"
				:value="plan.value"
				:isActive="plan.activated === 1"
				:productId="plan.product_id"
				:planId="plan.id"
				:isRecommended="index === 2"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Plan from "@/components/PlanItem.vue";

export default {
	components: { Plan },
	computed: {
		...mapGetters({
			plans: "memberPlan/getPlans",
		}),
	},
};
</script>

<style lang="scss" scoped>
.plans {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(191px, 1fr));
	gap: 32px;
}
</style>
