<template>
	<div class="hosting-plans">
		<TextPlay
			class="hosting-plans__title"
			color="var(--fontcolor)"
			weight="semibold"
			size="2xl"
			:text="$t('settings.hosting_plans.title')"
		/>
		<TextPlay
			class="hosting-plans__desc"
			color="var(--text-color)"
			size="sm"
			:text="$t('settings.hosting_plans.desc')"
		/>

		<div class="hosting-plans__current-plan">
			<div class="hosting-plans__current-plan__info">
				<TextPlay
					color="var(--fontcolor)"
					weight="bold"
					size="sm"
					:text="$t('settings.hosting_plans.your_current_plan')"
				/>
				<TextPlay
					color="var(--text-color)"
					size="sm"
					:text="`${activePlan.storage_limit} - ${activePlan.value}`"
				/>
			</div>
			<div class="hosting-plans__current-plan__used">
				<TextPlay
					color="var(--fontcolor)"
					weight="bold"
					size="xs"
					:text="$t('settings.hosting_plans.used_storage')"
				/>
				<div class="progress-area">
					<b-progress
						height="4px"
						:value="usagePercentage"
						:max="100"
					></b-progress>
					<TextPlay
						color="var(--fontcolor)"
						size="xs"
						:text="`${storageUsage} / ${activePlan.storage_limit}`"
					/>
				</div>
			</div>
		</div>

		<GridPlans />
	</div>
</template>

<script>
import GridPlans from "./GridPlans.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	components: { GridPlans },
	data() {
		return {
			usagePercentage: 0,
			storageLimit: 0,
			storageUsage: 0,
			plans: [],
		};
	},
	async mounted() {
		await this.getPlans();
		this.getPorcentage();
	},
	computed: {
		...mapGetters({
			activePlan: "memberPlan/getActivePlan",
		}),
	},
	methods: {
		...mapActions({
			fetchMe: "memberPlan/fetchMe",
			fetchPlans: "memberPlan/fetchPlans",
		}),
		async getPlans() {
			try {
				this.$root.$emit("loadOn");
				this.plans = await this.fetchPlans();
			} catch (error) {
				this.$bvToast.toast(this.$t("delete_site_modal.error_to_load_plans_desc"), {
					title: this.$t("delete_site_modal.error_to_load_plans"),
					variant: "danger",
					autoHideDelay: 5000,
					appendToast: true,
				});
			} finally {
				this.$root.$emit("loadOff");
			}
		},
		async getPorcentage() {
			const response = await this.fetchMe();
			this.usagePercentage = parseInt(response.percentage, 10);
			this.storageLimit = response.storage_limit;
			this.storageUsage = response.used_storage;
		},
	},
};
</script>

<style lang="scss" scoped>
.hosting-plans {
	display: flex;
	flex-direction: column;

	&__title {
		margin-bottom: 8px;
	}

	&__desc {
		margin-bottom: 24px;
	}

	&__current-plan {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px;
		background-color: var(--caption-bg-box);
		border-radius: 8px;
		margin-bottom: 70px;

		&__info {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		&__used {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.progress-area {
				width: 356px;
				display: flex;
				align-items: center;
				gap: 14px;

				.progress {
					background-color: var(--progress-bg);
					width: 260px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.hosting-plans {
		&__current-plan {
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;

			&__used {
				width: 100%;
				.progress-area {
					width: 100%;
					justify-content: space-between;

					.progress {
						width: 60%;
					}
				}
			}
		}
	}
}
</style>
