<template>
	<div
		class="appearance"
		data-anima="top"
	>
		<TextPlay
			class="appearance__title"
			:text="$t('settings.appearance.page_title')"
			color="var(--fontcolor)"
			size="2xl"
			weight="bold"
		/>

		<SettingsItem
			icon="menu-1"
			:title="$t('settings.appearance.menu_logo')"
			:desc="$t('settings.appearance.menu_logo_desc')"
			:toggle="false"
			topDivider
		>
			<div class="mt-3 d-flex align-items-center">
				<ImagePickerPlay
					@click="showModalUploadLogo"
					w="300px"
					:src="logo"
				/>
				<div
					v-if="logo"
					class="cursor-pointer ml-2 d-flex align-items-center"
				>
					<IconSax
						size="8px"
						name="close-x"
						package="custom"
						class="mr-1"
					/>
					<TextPlay
						@click="remove('logo')"
						:text="$t('image_picker.remove_image')"
						size="xs"
						color="var(--fontcolor)"
					/>
				</div>
			</div>
			<TextPlay
				class="mt-3"
				size="sm"
				color="var(--text-color)"
				:text="$t('settings.appearance.menu_logo_tip')"
			/>
		</SettingsItem>

		<SettingsItem
			icon="voomp"
			iconPackage="custom"
			:title="$t('settings.appearance.plataform_icon')"
			:desc="$t('settings.appearance.plataform_icon_desc')"
			:toggle="false"
		>
			<div class="mt-3 d-flex align-items-center">
				<ImagePickerPlay
					@click="showModalUploadLogomarca"
					h="100px"
					w="100px"
					:src="logomarca"
				/>
				<div
					v-if="logomarca"
					class="cursor-pointer ml-2 d-flex align-items-center"
				>
					<IconSax
						size="8px"
						name="close-x"
						package="custom"
						class="mr-1"
					/>
					<TextPlay
						@click="remove('logomarca')"
						:text="$t('image_picker.remove_image')"
						size="xs"
						color="var(--fontcolor)"
					/>
				</div>
			</div>
			<TextPlay
				class="mt-3"
				size="sm"
				color="var(--text-color)"
				:text="$t('settings.appearance.plataform_icon_tip')"
			/>
		</SettingsItem>

		<SettingsItem
			icon="gallery"
			:title="$t('settings.appearance.background_image')"
			:desc="$t('settings.appearance.background_image_desc')"
			:toggle="false"
		>
			<div class="mt-3 d-flex align-items-center">
				<ImagePickerPlay
					@click="showModalUploadBackground"
					w="300px"
					:src="login_background_image"
				/>
				<div
					v-if="login_background_image"
					class="cursor-pointer ml-2 d-flex align-items-center"
				>
					<IconSax
						size="8px"
						name="close-x"
						package="custom"
						class="mr-1"
					/>
					<TextPlay
						@click="remove('login_background_image')"
						:text="$t('image_picker.remove_image')"
						size="xs"
						color="var(--fontcolor)"
					/>
				</div>
			</div>
			<TextPlay
				class="mt-3"
				size="sm"
				color="var(--text-color)"
				:text="$t('settings.appearance.background_image_tip')"
			/>
		</SettingsItem>

		<SettingsItem
			icon="paintbucket"
			:title="$t('settings.appearance.highlited_color')"
			:desc="$t('settings.appearance.highlited_color_desc')"
			:toggle="false"
		>
			<div class="appearance__highlited mt-3 d-flex align-items-center">
				<v-swatches
					v-model="main_color"
					:swatches="swatches"
					@input="changedColor"
					inline
				/>
				<TextPlay
					class="ml-4 ml-md-5"
					color="var(--fontcolor)"
					size="xs"
					:text="$t('settings.custom.txt13')"
				/>
				<InputColorPicker
					class="input-color ml-1 ml-md-3"
					@change="changedColor"
					v-model="main_color"
					shapes="squares"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			icon="smallcaps"
			:title="$t('settings.appearance.name_and_desc_plataform')"
			:desc="$t('settings.appearance.name_and_desc_plataform_desc')"
			:toggle="false"
		>
			<div class="mt-3">
				<ButtonPlay
					type="normal"
					:text="$t('settings.appearance.edit_name_and_desc')"
					@click="openModalConfigPainel"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			icon="eye-slash"
			:title="$t('settings.appearance.hide_courses')"
			:desc="$t('settings.appearance.hide_courses_desc')"
			@change="update"
			:value="hide_courses"
			param="hide_courses"
		/>

		<SettingsItem
			icon="slider-vertical"
			:title="$t('settings.appearance.show_courses')"
			:desc="$t('settings.appearance.show_courses_desc')"
			@change="updateView"
			param="view_course"
			:value="view_course"
		>
			<div
				class="mt-3 appearance__cover-selection"
				v-if="view_course"
			>
				<TextPlay
					class="appearance__cover-selection__title"
					color="var(--fontcolor)"
					weight="bold"
					size="sm"
					:text="$t('settings.appearance.cover_format')"
				/>
				<b-form-radio-group
					class="appearance__cover-selection__radio-list"
					v-model="home_vertical"
					@change="changeView"
				>
					<b-form-radio value="film_cover">{{ $t("settings.appearance.big_cover") }}</b-form-radio>
					<b-form-radio value="horizontal_cover">{{ $t("settings.appearance.horizontal_cover") }}</b-form-radio>
				</b-form-radio-group>
			</div>
		</SettingsItem>

		<SettingsItem
			icon="main-component"
			:title="$t('settings.appearance.show_modules')"
			:desc="$t('settings.appearance.show_modules_desc')"
			@change="updateView"
			:value="without_category"
			param="without_category"
		>
			<div
				class="mt-3 appearance__cover-selection"
				v-if="without_category"
			>
				<TextPlay
					class="appearance__cover-selection__title"
					color="var(--fontcolor)"
					weight="bold"
					size="sm"
					:text="$t('settings.appearance.cover_format')"
				/>
				<b-form-radio-group
					class="appearance__cover-selection__radio-list"
					v-model="home_vertical"
					@change="changeView"
				>
					<b-form-radio value="film_cover">{{ $t("settings.appearance.big_cover") }}</b-form-radio>
					<b-form-radio value="horizontal_cover">{{ $t("settings.appearance.small_cover") }}</b-form-radio>
					<b-form-radio value="small_vertical_cover">{{ $t("settings.appearance.horizontal_cover") }}</b-form-radio>
				</b-form-radio-group>
			</div>
		</SettingsItem>

		<SettingsItem
			icon="slider-horizontal"
			:title="$t('settings.appearance.show_carousel')"
			:desc="$t('settings.appearance.show_carousel_desc')"
			@change="update"
			:value="home_carousel"
			param="home_carousel"
		/>

		<SettingsItem
			icon="link-2"
			:title="$t('settings.appearance.show_categories')"
			:desc="$t('settings.appearance.show_categories_desc')"
			@change="update"
			:value="enable_category"
			param="enable_category"
		/>

		<SettingsItem
			icon="play-circle"
			:title="$t('settings.appearance.show_keep_leraning')"
			:desc="$t('settings.appearance.show_keep_leraning_desc')"
			@change="update"
			:value="learning_progression"
			param="learning_progression"
		/>

		<SettingsItem
			icon="moon"
			:title="$t('settings.appearance.fixed_theme')"
			:desc="$t('settings.appearance.fixed_theme_desc')"
			@change="update"
			:value="theme_fixed_painel"
			param="theme_fixed_painel"
		>
			<div
				class="mt-3"
				v-if="theme_fixed_painel"
			>
				<b-form-radio-group
					id="radio-group-2"
					class="mt-3"
					v-model="theme_fixed_painel_color"
					:name="$t('settings.custom.txt28')"
					@change="changeValueThemeFixed"
				>
					<b-form-radio value="light">{{ $t("settings.custom.txt29") }}</b-form-radio>
					<b-form-radio value="dark">{{ $t("settings.custom.txt30") }}</b-form-radio>
				</b-form-radio-group>
			</div>
		</SettingsItem>

		<ModalConfigPainel />
		<ModalUpload />
	</div>
</template>

<script>
import { mapActions } from "vuex";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import SettingsItem from "./SettingsItem.vue";
import ImagePickerPlay from "@/components/common/ImagePickerPlay.vue";
import ModalConfigPainel from "@/components/ModalConfigPainel.vue";
import ModalUpload from "@/components/ModalUpload.vue";
import InputColorPicker from "vue-native-color-picker";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export default {
	components: {
		SettingsItem,
		ImagePickerPlay,
		InputColorPicker,
		VSwatches,
		ModalUpload,
		ModalConfigPainel,
	},
	data() {
		return {
			main_color: "#002363",
			swatches: [
				["#002363"],
				["#002363"],
				["#FF0C37"],
				["#FFCB37"],
				["#2ED7EE"],
				["#5D21D2"],
				["#5A68DF"],
				["#FF37D3"],
			],
			without_category: false,
			view_course: false,
			home_carousel: false,
			home_vertical: "",
			hide_courses: false,
			enable_category: false,
			theme_fixed_painel: false,
			theme_fixed_painel_color: "dark",
			module_view: false,
			course_view: false,
			learning_progression: false,
			login_background_image: "",
			login_background_image_id: null,
			logomarca: "",
			logo: "",
		};
	},
	methods: {
		...mapActions({
			actionDefineTheme: "config/actionDefineTheme",
			actionSaveSettings: "config/actionSaveSettings",
		}),
		getKeys(key) {
			var keys = [];
			if (key) {
				keys = [key];
			} else {
				keys = [
					"main_color",
					"without_category",
					"view_course",
					"enable_category",
					"home_carousel",
					"home_vertical",
					"hide_courses",
					"theme_fixed_painel",
					"theme_fixed_painel_color",
					"learning_progression",
					"login_background_image",
					"logomarca",
					"logo",
				];
			}
			var result = "";
			for (let i = 0; i < keys.length; i++) {
				if (keys.length - 1 == i) {
					result += `keys[]=${keys[i]}`;
				} else {
					result += `keys[]=${keys[i]}&`;
				}
			}
			serviceMeta
				.search(result)
				.then(resp => {
					if (resp.main_color !== undefined) {
						this.main_color = resp.main_color;
					}
					if (resp.login_background_image !== undefined) {
						this.login_background_image = resp?.login_background_image?.cdn_url;
					}
					if (resp.logomarca !== undefined) {
						this.logomarca = resp.logomarca;
					}
					if (resp.logo !== undefined) {
						this.logo = resp.logo;
					}

					if (resp.without_category !== undefined) {
						if (resp.without_category === "off" || resp.without_category === null) {
							this.without_category = false;
							this.view_course = true;
						} else {
							this.without_category = true;
							this.view_course = false;
						}
					}

					if (resp.view_course !== undefined && resp.view_course !== null) {
						if (resp.view_course === "off") {
							this.view_course = false;
							this.without_category = true;
						} else {
							this.view_course = true;
							this.without_category = false;
						}
					}

					if (resp.enable_category !== undefined) {
						if (resp.enable_category === "off" || resp.enable_category === null) {
							this.enable_category = false;
						} else {
							this.enable_category = true;
						}
					}

					if (resp.home_vertical !== undefined) {
						if (resp.home_vertical === false || resp.home_vertical === "off") {
							this.home_vertical = "horizontal_cover";
						} else if (resp.home_vertical === true || resp.home_vertical === "on") {
							this.home_vertical = "film_cover";
						} else {
							this.home_vertical = resp.home_vertical;
						}
					}

					if (resp.home_carousel !== undefined) {
						if (resp.home_carousel === "off" || resp.home_carousel === null) {
							this.home_carousel = false;
						} else {
							this.home_carousel = true;
						}
					}
					if (resp.hide_courses !== undefined) {
						if (resp.hide_courses === null || resp.hide_courses === "on") {
							this.hide_courses = true;
						} else {
							this.hide_courses = false;
						}
					}
					if (resp.theme_fixed_painel !== undefined) {
						if (resp.theme_fixed_painel === "off") {
							this.theme_fixed_painel = false;
						} else {
							this.theme_fixed_painel = true;
						}
					}
					if (resp.theme_fixed_painel_color !== undefined) {
						if (resp.theme_fixed_painel_color !== null) {
							this.theme_fixed_painel_color = resp.theme_fixed_painel_color;
						} else {
							this.changeValueThemeFixed("light");
						}
						this.$root.$emit("loadOff");
					}

					if (resp.learning_progression !== undefined) {
						if (resp.learning_progression === "off") {
							this.learning_progression = false;
						} else {
							this.learning_progression = true;
						}
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		mensageUpdate() {
			this.$bvToast.toast(this.$t("settings.custom.txt31"), {
				title: this.$t("settings.custom.txt32"),
				variant: "success",
				autoHideDelay: 5000,
				appendToast: true,
			});
		},
		update(item, showMessage = true) {
			let data = {
				id: item[1],
				value: item[0] ? "on" : "off",
			};
			this.actionSaveSettings(data).then(() => {
				if (showMessage) {
					this.mensageUpdate();
				}
				this.getKeys(item[1]);
			});
		},
		updateView(item) {
			let itemChanged;
			this.update(item);

			const [hasItem, viewType] = item;

			if (viewType === "view_course") {
				itemChanged = [!hasItem, "without_category"];
				this.changeView(hasItem ? "film_cover" : "horizontal_cover", false);
			} else {
				itemChanged = [!hasItem, "view_course"];

				if (!hasItem) {
					this.changeView("film_cover", false);
				} else {
					this.changeView("horizontal_cover", false);
				}
			}

			this.update(itemChanged, false);
		},
		changeValueThemeFixed(theme) {
			let data = {
				id: "theme_fixed_painel_color",
				value: theme,
			};

			serviceMeta.postID(data).then(() => {
				this.mensageUpdate();
				this.getKeys("theme_fixed_painel_color");
				this.actionDefineTheme(theme);
			});
		},
		changeView(home, showMessage = true) {
			let data = {
				id: "home_vertical",
				value: home,
			};
			this.home_vertical = home;
			serviceMeta.postID(data).then(() => {
				if (showMessage) {
					this.mensageUpdate();
				}
				this.getKeys("home_vertical");
			});
		},
		changedColor() {
			var data = {
				id: "main_color",
				value: this.main_color,
			};

			serviceMeta.postID(data).then(() => {
				if (this.main_color !== null) {
					const doc = document.documentElement;
					doc.setAttribute("data-color", this.main_color);
					doc.style.setProperty("--maincolor", this.main_color);
					doc.style.setProperty("--maincolorn", this.$func.convertColor(this.main_color, 210));
					doc.style.setProperty("--maincolortrans", this.main_color + "0f");

					localStorage.setItem("mainColor", this.main_color);
				}
				this.mensageUpdate();
				this.getKeys("main_color");
				this.$root.$emit("changedmaincolor", this.color);
				this.$root.$emit("loadOff");
			});
		},
		updateLogoPainel() {
			var data = {
				id: "logo",
				value: this.logo,
			};
			serviceMeta.postID(data).then(() => {
				this.getKeys("logo");
				this.mensageUpdate();
				this.$root.$emit("changedlogo");
			});
		},
		updateLogomarcaPainel() {
			var data = {
				id: "logomarca",
				value: this.logomarca,
			};
			serviceMeta.postID(data).then(() => {
				this.getKeys("logomarca");
				this.mensageUpdate();
				this.$root.$emit("changedlogomarca");
			});
		},
		updateBackgroundLogin() {
			var data = {
				id: "login_background_image",
				value: this.login_background_image_id,
			};
			serviceMeta.postID(data).then(() => {
				this.mensageUpdate();
				this.getKeys("login_background_image");
			});
		},
		remove(item) {
			var data = {
				id: item,
				value: null,
			};
			serviceMeta.postID(data).then(() => {
				this.mensageUpdate();
				this.getKeys(item);
			});
		},
		openModalConfigPainel() {
			this.$root.$emit("openeditproject");
			this.$root.$emit("bv::show::modal", "modal-config-painel", "#btnShow");
		},
		showModalUploadLogo() {
			this.$root.$emit("logopainel");
			this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
		},
		showModalUploadLogomarca() {
			this.$root.$emit("logomarcapainel");
			this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
		},
		showModalUploadBackground() {
			this.$root.$emit("backgroundlogin");
			this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
		},
		getText() {
			return `${this.$t("settings.custom.setup_course_part1")} <strong>${this.$t(
				"settings.custom.setup_course"
			)}</strong>${this.$t("settings.custom.setup_course_part2")}`;
		},
		getTextModule() {
			return `${this.$t("settings.custom.setup_module_part1")} <strong>${this.$t(
				"settings.custom.setup_module"
			)}</strong>${this.$t("settings.custom.setup_module_part2")}`;
		},
	},
	mounted() {
		this.getKeys();
		this.$root.$on("newlogopainel", data => {
			this.logo = data.url;
			this.updateLogoPainel();
		});
		this.$root.$on("newlogomarcapainel", data => {
			this.logomarca = data.url;
			this.updateLogomarcaPainel();
		});
		this.$root.$on("newbackgroundlogin", data => {
			this.login_background_image = data.url;
			this.login_background_image_id = data.id;
			this.updateBackgroundLogin();
		});
	},
};
</script>

<style lang="scss" scoped>
.appearance {
	&__title {
		margin-bottom: 32px;
	}

	&__cover-selection {
		&__title {
			margin-bottom: 16px;
		}

		&__radio-list {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}

	.input-color {
		transform: scale(0.63);
		height: 52px !important;
		width: 52px !important;
		border-radius: 8px;
		padding: 0;
	}
}

@media screen and (max-width: 768px) {
	.appearance {
		&__highlited {
			padding-bottom: 90px;
		}
	}
}
</style>

<style lang="scss">
.appearance {
	.vue-swatches__wrapper {
		height: 32px !important;
		gap: 16px;
	}

	.vue-swatches__row,
	.vue-swatches__swatch {
		border-radius: 8px !important;
		height: 32px !important;
		width: 32px !important;
	}
}
</style>
