<template>
	<div class="webhooks">
		<TextPlay
			class="webhooks__title"
			size="2xl"
			weight="bold"
			color="var(--fontcolor)"
			:text="$t('settings.webhooks.title')"
		/>
		<TextPlay
			class="webhooks__desc"
			size="sm"
			color="var(--text-color)"
			:text="$t('settings.webhooks.desc')"
		/>
		<ButtonPlay
			class="webhooks__btn-add"
			@click="openModalCreateWebhook"
			type="normal"
			:text="$t('settings.webhooks.add_webhook')"
		/>

		<div class="webhooks__list">
			<div
				class="webhooks__list__item"
				v-for="(item, index) in getWebhooks"
				:key="item.name + item.id + index"
			>
				<div class="webhooks__list__item__info">
					<TextPlay
						weight="semibold"
						size="sm"
						color="var(--fontcolor)"
						:text="item.name"
					/>
					<TextPlay
						v-if="!itemEditing || itemEditing.id !== item.id"
						size="sm"
						color="var(--fontcolor)"
						:text="item.url"
					/>
					<InputPlay
						v-if="itemEditing && itemEditing.id === item.id"
						v-model="url_edit"
						type="text"
						class="edit-name"
						@enter="updateNameWebhook(item)"
					/>
				</div>
				<div class="webhooks__list__item__actions">
					<div
						class="btn-save"
						@click="updateNameWebhook(item)"
						v-if="itemEditing && itemEditing.id === item.id"
					>
						<IconSax
							:id="'btn-save-' + item.id"
							class="action"
							size="14px"
							name="tick-square"
							primary
						/>
						<TextPlay
							color="var(--maincolor)"
							size="sm"
							:text="$t('settings.webhooks.save')"
						/>
					</div>
					<IconSax
						v-if="!itemEditing || itemEditing.id !== item.id"
						:id="'btn-edit-' + item.id"
						class="action"
						size="20px"
						name="edit"
						@click="editWebhook(item)"
					/>
					<b-tooltip
						v-if="!getIsMobile && (!itemEditing || itemEditing.id !== item.id)"
						:target="'btn-edit-' + item.id"
						:title="$t('settings.webhooks.edit')"
						placement="top"
					/>
					<IconSax
						:id="'btn-delete-' + item.id"
						class="action"
						size="20px"
						name="trash"
						@click="openModalDeleteWebhook(item)"
					/>
					<b-tooltip
						v-if="!getIsMobile"
						:target="'btn-delete-' + item.id"
						:title="$t('settings.webhooks.delete')"
						placement="top"
					/>
					<IconSax
						:id="'btn-test-' + item.id"
						class="action"
						size="20px"
						name="document-forward"
						@click="openModalTestWebhook(item)"
					/>
					<b-tooltip
						v-if="!getIsMobile"
						:target="'btn-test-' + item.id"
						:title="$t('settings.webhooks.send_test')"
						placement="top"
					/>
				</div>
			</div>
		</div>

		<ModalWebhooks />
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalWebhooks from "@/components/configs/ModalWebhooks.vue";
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
	data() {
		return {
			itemEditing: null,
			lista: [],
			url_edit: null,
		};
	},
	components: {
		ModalWebhooks,
	},
	computed: {
		...mapGetters({
			getWebhooks: "webhooks/getWebhooks",
			getIsMobile: "layout/getIsMobile",
		}),
	},
	methods: {
		...mapActions({
			actionShowModal: "app/actionShowModal",
			actionFetchWebhooks: "webhooks/fetchWebhooks",
		}),
		openModalTestWebhook(item) {
			this.actionShowModal({ id: "ModalTestWebhook", item: item });
		},
		openModalDeleteWebhook(item) {
			this.actionShowModal({ id: "ModalDeleteWebhook", item: item });
		},
		openModalCreateWebhook() {
			this.$root.$emit("bv::show::modal", "modal-config-webhooks", "#btnShow");
		},
		async updateNameWebhook(webhook) {
			let data = webhook.obj;
			data.url = this.url_edit;

			try {
				await serviceCallback.update(data);
				this.$bvToast.toast("URL atualizada com sucesso", {
					title: "Webhook",
					variant: "success",
					autoHideDelay: 5000,
					appendToast: true,
				});

				this.itemEditing = null;
				this.fetchWebhooks();
			} catch (error) {
				this.$bvToast.toast("Tente novamente mais tarde", {
					title: "Erro ao atualizar URL",
					variant: "danger",
					autoHideDelay: 5000,
					appendToast: true,
				});
			}
		},
		editWebhook(item) {
			this.url_edit = item.url;
			this.itemEditing = item;
		},
		async fetchWebhooks() {
			try {
				await this.actionFetchWebhooks();
			} catch (error) {
				this.$bvToast.toast(this.$t("settings.webhooks.error_to_load_desc"), {
					title: this.$t("settings.webhooks.error_to_load"),
					variant: "danger",
					autoHideDelay: 5000,
					appendToast: true,
				});
			}
		},
	},
	async mounted() {
		await this.fetchWebhooks();
	},
};
</script>

<style lang="scss">
.webhooks {
	&__title {
		margin-bottom: 8px;
	}
	&__desc {
		margin-bottom: 24px;
	}
	&__btn-add {
		margin-bottom: 16px;
	}

	&__list {
		&__item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px 24px;
			border-radius: 8px;
			background-color: var(--caption-bg-box);
			margin-bottom: 16px;

			&__info {
				display: flex;
				flex-direction: column;
				gap: 8px;
				width: 100%;
				max-width: 440px;

				input {
					border: none;
					border-bottom: 1px solid var(--neutral-gray-300);
					padding: 0;
					width: 100%;
				}
			}

			&__actions {
				display: flex;
				gap: 32px;

				.action {
					cursor: pointer;
				}

				.btn-save {
					display: flex;
					align-items: center;
					gap: 8px;
					cursor: pointer;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.webhooks {
		&__list {
			&__item {
				flex-direction: column;
				align-items: flex-start;
				padding: 16px;
				gap: 24px;

				&__info {
					gap: 4px;
				}

				&__actions {
					gap: 14px;
				}
			}
		}
	}
}
</style>
