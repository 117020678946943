<template>
	<div class="settings-item-containter">
		<DividerPlay v-if="topDivider" />
		<div
			:style="computedStyle"
			class="settings-item"
		>
			<div class="settings-item__header">
				<IconSax
					v-if="icon"
					:name="icon"
					:package="iconPackage"
					size="16px"
					primary
				/>
				<TextPlay
					v-if="title"
					v-html="title"
					color="var(--fontcolor)"
					weight="bold"
				/>
				<b-form-checkbox
					v-if="toggle"
					v-model="contentValue"
					@change="update"
					class="settings-item__header__toggle"
					size="lg"
					switch
				>
				</b-form-checkbox>
			</div>
			<TextPlay
				class="settings-item__desc"
				v-if="desc"
				v-html="desc"
				color="var(--text-color)"
				size="sm"
			/>
			<slot></slot>
		</div>
		<DividerPlay v-if="bottomDivider" />
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
		},
		desc: {
			type: String,
		},
		icon: {
			type: String,
		},
		iconPackage: {
			type: String,
			required: false,
			default: 'iconsax'
		},
		toggle: {
			type: Boolean,
			default: true,
		},
		param: {
			type: String,
		},
		value: {
			type: [String, Number, Boolean],
		},
		pt: {
			type: String,
		},
		pb: {
			type: String,
		},
		topDivider: {
			type: Boolean,
			default: false,
		},
		bottomDivider: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			contentValue: this.value,
		};
	},
	watch: {
		value() {
			this.contentValue = this.value;
		},
	},
	methods: {
		update() {
			this.$emit("change", [this.contentValue, this.param]);
		},
	},
	computed: {
		computedStyle() {
			return {
				paddingTop: this.pt,
				paddingBottom: this.pb,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.settings-item-containter {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.settings-item {
	display: flex;
	flex-direction: column;
	padding: 32px 0;
	width: 100%;

	&__header {
		display: flex;
		align-items: center;
		gap: 8px;
		width: 100%;
		margin-bottom: 8px;

		&__toggle {
			margin-left: auto;
		}
	}

	&__desc {
		width: 90%;
	}

	&__content {
		margin-top: 16px;
		width: 100%;
	}
}
</style>
