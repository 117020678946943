<template>
	<div class="security">
		<TextPlay
			class="title"
			size="2xl"
			weight="bold"
			color="var(--fontcolor)"
			:text="$t('settings.security.delete_plataform')"
		/>
		<TextPlay
			size="sm"
			color="var(--text-color)"
			:text="$t('settings.security.desc')"
		/>
		<ButtonPlay
			@click="openModalDeletePainel"
			type="normal"
			:text="$t('settings.security.delete_plataform')"
		/>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	methods: {
		...mapActions({
			actionShowModal: "app/actionShowModal",
		}),
		openModalDeletePainel() {
			this.actionShowModal({ id: "ModalDeleteSite" });
		},
	},
};
</script>

<style lang="scss" scoped>
.security {
	.title {
		margin-bottom: 8px;
	}
	button {
		margin-top: 16px;
	}
}
</style>
