<template>
	<Layout>
		<div class="settings">
			<div class="settings__menu">
				<MenuSlide
					@changeType="changeType"
					:feature_access_free="feature_access_free"
				/>
			</div>
			<div
				ref="content"
				class="settings__content"
			>
				<General v-if="type === 'geral'" />
				<Appearance v-if="type === 'custom'" />
				<HostingPlans v-if="type === 'plans'" />
				<Security v-if="type === 'advanced'" />
				<CamposPersonalizados v-if="type === 'custom_camp'" />
				<FreeAccess v-if="type === 'free'" />
				<Webhooks v-if="type === 'webhooks'" />
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from "@/layout/Layout.vue";
import MenuSlide from "@/components/configs/MenuSlide.vue";
import General from "@/components/configs/General.vue";
import Appearance from "@/components/configs/Appearance.vue";
import Security from "@/components/configs/Security.vue";
import HostingPlans from "@/components/HostingPlans.vue";
import CamposPersonalizados from "@/components/configs/CamposPersonalizados.vue";
import FreeAccess from "@/components/FreeAccess.vue";
import Webhooks from "@/components/configs/Webhooks.vue";

import MetaService from "@/services/resources/MetaService";

const serviceMeta = MetaService.build();

export default {
	components: {
		Layout,
		MenuSlide,
		General,
		HostingPlans,
		Security,
		FreeAccess,
		Appearance,
		CamposPersonalizados,
		Webhooks,
	},
	data() {
		return {
			type: "geral",
			feature_access_free: false,
		};
	},
	async mounted() {
		await this.getFreeCourses();
	},
	methods: {
		changeType(type) {
			this.type = type;
			const el = this.$refs.content;
			el.scrollIntoView({
				block: "start",
			});
		},
		async getFreeCourses() {
			const resp = await serviceMeta.search("keys[]=feature_access_free");

			if (resp.feature_access_free === "on") {
				this.feature_access_free = true;
			} else {
				this.feature_access_free = false;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.settings {
	display: flex;
	gap: 35px;
	padding: 32px 29px;

	&__menu {
		width: 252px;
	}

	&__content {
		scroll-margin-top: 100px;
		width: calc(100% - 252px);
		max-width: 880px;
		min-height: 100vh;
	}
}

@media screen and (max-width: 768px) {
	.settings {
		flex-direction: column;
		padding: 16px;

		&__menu {
			width: 100%;
		}

		&__content {
			width: 100%;
		}
	}
}
</style>
