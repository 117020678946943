<template>
	<div class="free-access">
		<div class="free-access__top">
			<TextPlay
				color="var(--fontcolor)"
				weight="semibold"
				size="2xl"
				:text="$t('settings.free_access.title')"
			/>
			<TextPlay
				color="var(--text-color)"
				size="sm"
				:text="$t('settings.free_access.desc')"
			/>
		</div>

		<DividerPlay />

		<div class="free-access__about">
			<TextPlay
				color="var(--fontcolor)"
				weight="semibold"
				size="sm"
				:text="$t('settings.free_access.title')"
			/>
			<TextPlay
				color="var(--text-color)"
				size="sm"
				:text="$t('settings.free_access.tip')"
			/>
		</div>

		<div class="free-access__to-enable">
			<b-form-checkbox
				@input="handleToggle"
				v-model="isEnableFreeAccess"
				size="lg"
				switch
			/>
			<TextPlay
				class="free-access__title"
				color="var(--fontcolor)"
				size="sm"
				:text="$t('settings.free_access.enable_free_access')"
			/>
		</div>

		<SelectFormPlay
			v-if="isEnableFreeAccess"
			@input="resetSelectedItem"
			v-model="selectedType"
			:label="$t('settings.free_access.liberation_type')"
			:placeholder="$t('settings.free_access.liberation_type')"
			:options="liberationTypeOptions"
			name="liberation-type"
		/>

		<SelectFormPlay
			v-if="isEnableFreeAccess && selectedType === 'course_class'"
			v-model="selectedClass"
			:label="$t('settings.free_access.classes')"
			:placeholder="$t('settings.free_access.classes')"
			:options="classes"
		/>

		<SelectFormPlay
			v-if="isEnableFreeAccess && selectedType === 'signature'"
			v-model="selectedCombo"
			:label="$t('settings.free_access.combos')"
			:placeholder="$t('settings.free_access.combos')"
			:options="combos"
		/>
		<button
			class="btn-save-free-courses"
			:class="{ 'btn-save-free-courses--disabled': !selectedType || (!selectedClass && !selectedCombo) }"
			v-if="isEnableFreeAccess"
			@click="saveFreeAccess()"
		>
			{{ $t("settings.free_access.save_data") }}
		</button>
	</div>
</template>

<script>
import MetaService from "@/services/resources/MetaService";
import { mapActions, mapGetters } from "vuex";
const serviceMeta = MetaService.build();

export default {
	data() {
		return {
			isEnableFreeAccess: false,
			selectedType: null,
			liberationTypeOptions: [
				{ id: "course_class", label: this.$t("settings.free_access.classes") },
				{ id: "signature", label: this.$t("settings.free_access.combos") },
			],
			classes: [],
			combos: [],
			selectedClass: null,
			selectedCombo: null,
		};
	},
	computed: {
		...mapGetters({
			isFreeCoursesActive: "config/isFreeCoursesActive",
		}),
	},
	methods: {
		...mapActions({
			actionSaveSettings: "config/actionSaveSettings",
			actionClassLoadSelect: "courseClass/actionLoadSelect",
			actionSignatureLoadSelect: "signatures/actionLoadSelect",
		}),
		handleToggle() {
			if (!this.isEnableFreeAccess) {
				this.deactivateFreeCourses();
			}
		},
		async deactivateFreeCourses() {
			await this.actionSaveSettings({
				id: "free_courses",
				value: "off",
			});
		},
		async activateFreeCourses() {
			await this.actionSaveSettings({
				id: "free_courses",
				value: "on",
			});
		},
		resetForm() {
			this.selectedType = null;
			this.resetSelectedItem();
		},
		resetSelectedItem() {
			this.selectedClass = null;
			this.selectedCombo = null;
		},
		async getMeta() {
			this.$root.$emit("loadOn");
			const { free_courses, type, item_free_id } = await serviceMeta.search(
				"keys[]=free_courses&keys[]=type&keys[]=item_free_id"
			);

			this.isEnableFreeAccess = free_courses === "off" ? false : true;
			this.selectedType = type;

			if (type === "signature" && item_free_id) {
				this.selectedCombo = JSON.parse(item_free_id);
			} else if (item_free_id) {
				this.selectedClass = JSON.parse(item_free_id);
			}
			this.$root.$emit("loadOff");
		},
		async saveFreeAccess() {
			const newFreeItem = this.selectedType === "signature" ? this.selectedCombo : this.selectedClass;

			let data = {
				id: "store",
				free_courses: "on",
				type: this.selectedType,
				item_free_id: JSON.stringify(newFreeItem),
			};

			try {
				this.$root.$emit("loadOn");
				await serviceMeta.postID(data);
				await this.getMeta();

				this.$bvToast.toast(this.$t("settings.free_access.success_to_save_desc"), {
					title: this.$t("settings.free_access.success_to_save"),
					variant: "success",
					autoHideDelay: 5000,
					appendToast: true,
				});
			} catch (error) {
				this.$bvToast.toast(this.$t("settings.free_access.error_to_save_desc"), {
					title: this.$t("settings.free_access.error_to_save"),
					variant: "danger",
					autoHideDelay: 5000,
					appendToast: true,
				});
			} finally {
				this.$root.$emit("loadOff");
			}
		},
	},
	async mounted() {
		await this.getMeta();
		this.combos = await this.actionSignatureLoadSelect();
		this.classes = await this.actionClassLoadSelect();
	},
};
</script>

<style lang="scss" scoped>
.free-access {
	display: flex;
	flex-direction: column;
	gap: 32px;

	&__top,
	&__about {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&__to-enable {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.btn-save-free-courses {
		border: none;
		border-radius: 8px;
		color: white;
		font-weight: 600;
		padding: 12px;
		text-align: center;
		width: 114px;
		background-color: var(--maincolor);

		&--disabled {
			background-color: var(--neutral-gray-200);
			color: var(--neutral-gray-600);
			pointer-events: none;
			cursor: not-allowed;
		}
	}
}
</style>
