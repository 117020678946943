<template>
  <div class="input-icon" v-click-outside="onClose" @click="onOpen">
    <button class="input-icon__selected">
      <span v-if="!value" class="input-icon__selected__placeholder">Ícone</span>
      <IconSax v-if="value" :name="value" />
      <IconSax name="arrow-down-1" :class="{'icon-up':opened}" />
    </button>
    <div :id="id" class="input-icon__container d-none">
      <div class="input-icon__content">
        <IconSax name="gallery" class="icon-item" @click="select('gallery')" />
        <IconSax name="video-play" class="icon-item" @click="select('video-play')" />
        <IconSax name="microphone-2" class="icon-item" @click="select('microphone-2')" />
        <IconSax name="subtitle" class="icon-item" @click="select('subtitle')" />
        <IconSax name="ruler&pen" class="icon-item" @click="select('ruler&pen')" />
        <IconSax name="clipboard-tick" class="icon-item" @click="select('clipboard-tick')" />
        <IconSax name="task-square" class="icon-item" @click="select('task-square')" />
        <IconSax name="menu-board" class="icon-item" @click="select('menu-board')" />
        <IconSax name="folder" class="icon-item" @click="select('folder')" />
        <IconSax name="call" class="icon-item" @click="select('call')" />
        <IconSax name="message" class="icon-item" @click="select('message')" />
        <IconSax name="message-question" class="icon-item" @click="select('message-question')" />
        <IconSax name="sms" class="icon-item" @click="select('sms')" />
        <IconSax name="messages-2" class="icon-item" @click="select('messages-2')" />
        <IconSax name="user" class="icon-item" @click="select('user')" />
        <IconSax name="profile-2user" class="icon-item" @click="select('profile-2user')" />
        <IconSax name="percentage-square" class="icon-item" @click="select('percentage-square')" />
        <IconSax name="dollar-circle" class="icon-item" @click="select('dollar-circle')" />
        <IconSax name="trend-up" class="icon-item" @click="select('trend-up')" />
        <IconSax name="chart" class="icon-item" @click="select('chart')" />
        <IconSax name="shopping-cart" class="icon-item" @click="select('shopping-cart')" />
        <IconSax name="bag-2" class="icon-item" @click="select('bag-2')" />
        <IconSax name="buildings" class="icon-item" @click="select('buildings')" />
        <IconSax name="calculator" class="icon-item" @click="select('calculator')" />
        <IconSax name="archive-tick" class="icon-item" @click="select('archive-tick')" />
        <IconSax name="calendar" class="icon-item" @click="select('calendar')" />
        <IconSax name="monitor" class="icon-item" @click="select('monitor')" />
        <IconSax name="mobile" class="icon-item" @click="select('mobile')" />
        <IconSax name="star" class="icon-item" @click="select('star')" />
        <IconSax name="heart" class="icon-item" @click="select('heart')" />
        <IconSax name="clock" class="icon-item" @click="select('clock')" />
        <IconSax name="book" class="icon-item" @click="select('book')" />
        <IconSax name="global" class="icon-item" @click="select('global')" />
        <IconSax name="location" class="icon-item" @click="select('location')" />
        <IconSax name="teacher" class="icon-item" @click="select('teacher')" />
        <IconSax name="award" class="icon-item" @click="select('award')" />
        <IconSax name="briefcase" class="icon-item" @click="select('briefcase')" />
        <IconSax name="truck" class="icon-item" @click="select('truck')" />
        <IconSax name="shield-tick" class="icon-item" @click="select('shield-tick')" />
        <IconSax name="lock" class="icon-item" @click="select('lock')" />
        <IconSax name="music" class="icon-item" @click="select('music')" />
        <IconSax name="link-21" class="icon-item" @click="select('link-21')" />
        <IconSax name="export" class="icon-item" @click="select('export')" />
        <IconSax name="key" class="icon-item" @click="select('key')" />
        <IconSax name="box" class="icon-item" @click="select('box')" />
        <IconSax name="map" class="icon-item" @click="select('map')" />
        <IconSax name="medal-star" class="icon-item" @click="select('medal-star')" />
        <IconSax name="gameboy" class="icon-item" @click="select('gameboy')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputIcon",
  props: ["value"],
  data() {
    return {
      opened: false,
      id: null
    }
  },
  methods: {
    onOpen() {
      const iconContainer = document.getElementById(this.id);

      if(iconContainer && !iconContainer.classList.contains('d-none')){
        iconContainer.classList.add('d-none');
        this.opened = false;
      } else {
        const iconContainers = document.querySelectorAll('.input-icon__container');
        iconContainers.forEach(container => {
          if (!container.classList.contains('d-none')) {
            container.classList.add('d-none');
          }
        });

        iconContainer.classList.remove('d-none');
        this.opened = true;
      }
    },
    onClose() {
      const iconContainer = document.getElementById(this.id);
      if (iconContainer) {
        iconContainer.classList.add('d-none');
        this.opened = false;
      }
    },
    select(icon) {
      this.$emit("input", icon)
    }
  },
  updated() {
    const iconContainer = document.getElementById(this.id);
    if (iconContainer && iconContainer.classList.contains('d-none')) {
      this.opened = false;
    }
  },
  mounted () {
    this.id = this._uid
  }
}
</script>

<style lang="scss" scoped>
.icon-up {
  transform: rotate(180deg);
}

@mixin rounded-input {
    height: 40px;
    border: 1px solid #ededf0 !important;
    border-radius: 30px;
    font-weight: 400;
    font-size: 13px;
    color: var(--fontcolor);
}

.input-icon {
  @include rounded-input;
  min-width: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-icon__container {
  position: relative;
  width: 100%;
}

.input-icon__selected, button {
  cursor: pointer;
}

::v-deep .input-icon__selected {
  all: unset;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  svg path {
    fill: var(--fontcolor);
  }
}

.input-icon__selected__placeholder {
  color: var(--placecolor);
  opacity: 40%;
}

.input-icon__content {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--contentcolor2);
  border-radius: 0.5rem;
  z-index: 99;
  height: 20rem;
  // width: 22rem;
  display: grid;
  padding: 1.5rem;
  gap: 1.5rem;
  grid-template-columns: repeat(8, 1fr);
}

::v-deep .icon-item {
  align-self: center;
  cursor: pointer;

  svg path {
    fill: var(--fontcolor);
  }

  &:hover svg path {
    fill: var(--fontcolor-hover);
  }
}

</style>
