<template>
	<div>
		<b-modal
			id="modal-dominio"
			modal-class="modal-domain"
			hide-footer
			hide-header
			size="xl"
			@show="getAllDomains"
		>
			<div class="modal-domain__header">
				<div>
					<TextPlay
						color="var(--fontcolor)"
						weight="semibold"
						size="lg"
						:text="$t('modal_domain.my_domains')"
					/>
					<TextPlay
						color="var(--text-color)"
						size="sm"
						:text="$t('modal_domain.create_and_config')"
					/>
				</div>
				<IconSax
					size="24px"
					color="var(--fontcolor)"
					name="close-circle"
					@click="cancel"
				/>
			</div>

			<DividerPlay mt="24px" />

			<div
				v-if="respDomains.total <= 0"
				class="modal-domain__no-domains"
			>
				<IconSax
					color="var(--fontcolor)"
					size="40px"
					name="global"
				/>
				<TextPlay
					class="title"
					color="var(--fontcolor)"
					weight="semibold"
					size="lg"
					:text="$t('modal_domain.add_first')"
				/>
				<TextPlay
					color="var(--text-color)"
					:text="$t('modal_domain.config_your_domain')"
				/>
				<ButtonPlay
					@click="openNew"
					v-b-toggle="sidebarId"
					type="normal"
					:text="$t('modal_domain.add_domain')"
				/>
			</div>

			<div
				v-if="respDomains.total > 0"
				class="modal-domain__domains"
			>
				<div class="modal-domain__domains__header">
					<div class="cell">
						<TextPlay
							size="sm"
							color="var(--text-color)"
							weight="semibold"
							:text="$t('modal_domain.domains')"
						/>
					</div>
					<div class="cell">
						<TextPlay
							size="sm"
							color="var(--text-color)"
							weight="semibold"
							:text="$t('modal_domain.apointment')"
						/>
					</div>
					<div class="cell">
						<TextPlay
							size="sm"
							color="var(--text-color)"
							weight="semibold"
							:text="$t('modal_domain.ssl_status')"
						/>
					</div>
					<div class="cell">
						<TextPlay
							size="sm"
							color="var(--text-color)"
							weight="semibold"
							:text="$t('modal_domain.actions')"
						/>
					</div>
					<div class="cell"></div>
				</div>

				<div
					v-for="item in AllDominios"
					:key="item.id"
					class="modal-domain__domains__item"
				>
					<div class="cell">
						<TextPlay
							color="var(--fontcolor)"
							weight="semibold"
							:text="item.domain"
						/>
					</div>
					<div class="cell">
						<TextPlay
							color="var(--fontcolor)"
							weight="semibold"
							:text="statusMap(item.redirect_status)"
						/>
					</div>
					<div class="cell">
						<TextPlay
							color="var(--fontcolor)"
							weight="semibold"
							:text="statusMap(item.ssl_status)"
						/>
					</div>
					<div class="cell">
						<div
							class="cell__turn-main"
							v-if="item.type !== 'main'"
							@click="toFirstDomain(item.id)"
						>
							<TextPlay
								weight="semibold"
								size="xs"
								color="var(--maincolor)"
								:text="$t('modal_domain.turn_main')"
							/>
							<IconSax
								name="tick-circle"
								size="16px"
								color="var(--maincolor)"
							/>
						</div>
						<div
							class="cell__is-main"
							v-if="item.type == 'main'"
						>
							<TextPlay
								weight="semibold"
								size="xs"
								color="white"
								:text="$t('modal_domain.main')"
							/>
							<IconSax
								name="tick-circle"
								size="16px"
								color="white"
							/>
						</div>
					</div>
					<div class="cell">
						<div
							class="cell__delete"
							@click="deletarDomain(item.id)"
						>
							<IconSax name="trash" />
						</div>
					</div>
				</div>

				<div class="modal-domain__domains__add">
					<PaginationPlay
						v-if="respDomains.total > respDomains.per_page"
						@onSelectPage="changePage"
						@onPrev="toPage('prev')"
						@onNext="toPage('next')"
						:total="respDomains.total"
						:currentPage="respDomains.current_page"
						:perPage="respDomains.per_page"
						maxVisible="4"
					/>
					<ButtonPlay
						type="normal"
						:text="$t('modal_domain.add_domain')"
						@click="openNew"
						v-b-toggle="sidebarId"
					/>
				</div>
			</div>
		</b-modal>

		<DomainSidebar :id="sidebarId" />
	</div>
</template>

<script>
import DomainSidebar from "@/components/configs/DomainSidebar.vue";
import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();

export default {
	data() {
		return {
			sidebarId: "domain-sidebar",
			dominioUrl: "",
			url_domain: process.env.VUE_URL_DOMAIN,
			totalDomain: 0,
			AllDominios: [],
			respDomains: {},
			pageDomain: 1,
			AllDominiosFirst: [],
			currentPageDomain: 1,
			lastPageDomain: 1,
		};
	},
	components: {
		DomainSidebar,
	},
	methods: {
		cancel() {
			this.$root.$emit("bv::hide::modal", "modal-dominio", "#btnShow");
		},
		changePage(page) {
			this.currentPageDomain = page;
			this.getAllDomains();
		},
		statusMap(status) {
			const statusTranslated = {
				waiting: this.$t("modal_domain.waiting"),
				generated: this.$t("modal_domain.generated"),
				ready: this.$t("modal_domain.ready"),
			};

			return statusTranslated[status];
		},
		toPage(data) {
			if (data === "prev") {
				if (this.currentPageDomain !== 1) {
					this.currentPageDomain = this.currentPageDomain - 1;
					this.getAllDomains();
				}
			} else {
				if (this.currentPageDomain !== this.lastPageDomain) {
					this.currentPageDomain = this.currentPageDomain + 1;
					this.getAllDomains();
				}
			}
		},
		toFirstDomain(id) {
			this.$root.$emit("loadOn");
			serviceDomain
				.postUnicID(id)
				.then(() => {
					this.getAllDomains();
					this.$root.$emit("loadOff");
				})
				.catch(() => {
					this.$root.$emit("loadOff");
				});
		},
		deletarDomain(id) {
			this.$root.$emit("loadOn");
			serviceDomain
				.destroy(id)
				.then(() => {
					if (this.AllDominios.length === 1 && this.currentPageDomain > 1) {
						this.changePage(this.currentPageDomain - 1);
					}
					this.getAllDomains();
					this.$root.$emit("loadOff");
				})
				.catch(() => {
					this.$root.$emit("loadOff");
				});
		},
		openNew() {
			this.$root.$emit("bv::hide::modal", "modal-dominio", "#btnShow");
		},
		getAllDomains() {
			this.$root.$emit("loadOn");
			serviceDomain
				.search("page=" + this.currentPageDomain)
				.then(resp => {
					this.respDomains = resp;
					this.AllDominios = resp.data;
					this.totalDomain = resp.total;
					this.currentPageDomain = resp.current_page;
					this.lastPageDomain = resp.last_page;
					this.$root.$emit("loadOff");
				})
				.catch(() => {
					this.$root.$emit("loadOff");
				});
		},
		closeNew() {
			this.$root.$emit("bv::show::modal", "modal-dominio", "#btnShow");
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-domain {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: start;

		.isax {
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	&__no-domains {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 475px;
		text-align: center;
		margin: auto;
		height: 80%;

		.isax {
			margin-bottom: 16px;
		}

		.title {
			margin-bottom: 8px;
		}

		button {
			margin-top: 24px;
		}
	}

	&__domains {
		display: flex;
		flex-direction: column;
		margin: 64px auto 0 auto;
		gap: 8px;
		width: 1100px;
		overflow-y: scroll;

		&__header {
			display: grid;
			grid-template-columns: 1.4fr repeat(3, 1fr) 0.1fr;
			padding: 0 40px 18px 40px;
			width: 1100px;
		}

		&__item {
			display: grid;
			grid-template-columns: 1.4fr repeat(3, 1fr) 0.1fr;
			background-color: var(--neutral-gray-800);
			[data-theme="light"] & {
				background-color: var(--neutral-gray-80);
			}
			padding: 20px 40px;
			border-radius: 8px;
			width: 1100px;

			.cell {
				display: flex;
				align-items: center;

				.text-play {
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				&__turn-main {
					display: flex;
					align-items: center;
					gap: 8px;
					cursor: pointer;
					background-color: transparent;
					border: 1px solid var(--maincolor);
					border-radius: 50px;
					padding: 8px 12px;
				}

				&__is-main {
					display: flex;
					align-items: center;
					gap: 8px;
					background-color: var(--maincolor);
					border-radius: 50px;
					padding: 8px 12px;
				}

				&__delete {
					display: flex;
					align-items: center;
					justify-content: end;
					width: 100%;

					.isax {
						cursor: pointer;
					}
				}
			}
		}

		&__add {
			display: flex;
			justify-content: end;
			margin-top: 32px;

			.pagination-play {
				margin-right: auto;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.modal-domain {
		&__domains {
			width: 300px;

			&__header {
				width: 1100px;
			}

			&__item {
				width: 1100px;
			}
		}
	}
}
</style>
