<template>
  <b-modal id="modal-config-painel" class="modal-config-painel" hide-header hide-footer body-class="p-0" centered>
    <div class="modal-config-painel__close d-flex justify-content-end w-100">
      <IconSax
        name="close-circle"
        color="var(--fontcolor)"
        @click="cancel"
      />
    </div>

    <TextPlay color="var(--fontcolor)" weight="semibold" :text="$t('config_panel.title')"/>

    <div class="modal-config-painel__inputs">
      <InputFormPlay :label="$t('config_panel.name')" v-model="nameProject" :placeholder="$t('config_panel.name')" />
      <InputFormPlay :label="$t('config_panel.description')" v-model="descricaoProject" :placeholder="$t('config_panel.description')" />
    </div>
      
    <div class="modal-config-painel__actions">
      <ButtonPlay @click="cancel" :text="$t('config_panel.cancel')" type="cancel" />
      <ButtonPlay @click="saveProject" :text="$t('config_panel.save')" type="normal" />
    </div>
  </b-modal>
</template>

<script>
import notify from "@/services/libs/notificacao";
import axios from "axios";
import Cookies from "js-cookie";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      nameProject: "",
      descricaoProject: "",
      currentSiteId: "",
    };
  },
  mounted() {
    this.$root.$on("openeditproject", () => {
      this.getCurrentSite();
    });
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
    }),
  },
  methods: {
    cancel() {
      this.$bvModal.hide("modal-config-painel");
    },
    getCurrentSite() {
      this.currentSiteId = this.getMe.current.currentSite.id;
      this.nameProject = this.getMe.current.currentSite.name;
      this.descricaoProject = this.getMe.current.currentSite.description;
    },
    saveProject() {
      var data = {
        name: this.nameProject,
        description: this.descricaoProject,
      };

      var that = this;
      this.$root.$emit("loadOn");
      axios
        .post(
          process.env.VUE_APP_API_HOST + `/site/` + this.currentSiteId,
          data,
          {
            headers: {
              Authorization: Cookies.get("auth_greennCourse"),
            },
          }
        )
        .then(function () {
          that.$root.$emit(
            "bv::hide::modal",
            "modal-config-painel",
            "#btnShow"
          );
          that.$root.$emit("loadOff");
          that.$root.$bvToast.toast(that.$t("settings.custom.txt31"), {
            title: that.$t("settings.custom.txt32"),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          var error = err.response.data;
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
          that.$root.$emit("loadOff");
        });
    },
  },
};
</script>

<style lang="scss">
.modal-config-painel {
	.modal-dialog {
		width: 800px !important;
		max-width: 800px;
	}

	.modal-content {
		padding: 16px 24px 16px 24px !important;
	}
}

@media (max-width: 768px) {
	.modal-config-painel {
		.modal-dialog {
			margin: auto;
			width: 95% !important;
			max-width: 95% !important;
		}
	}
}
</style>

<style lang="scss" scoped>
.modal-config-painel {
  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 24px;
  }

  &__inputs {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
