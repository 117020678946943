<template>
	<portal to="app">
		<b-sidebar
			:id="id"
			sidebar-class="domain-sidebar-container"
			width="425px"
			backdrop
			right
			no-header
			no-footer
			@change="pageDomain = 1"
			@hidden="newDomain = ''"
		>
			<div class="domain-sidebar">
				<div class="domain-sidebar__header">
					<IconSax
						v-b-toggle="id"
						name="close-circle"
					/>
				</div>

				<div class="domain-sidebar__body">
					<div
						v-if="pageDomain === 1"
						class="create-domain"
					>
						<TextPlay
							class="title"
							color="var(--fontcolor)"
							weight="semibold"
							:text="$t('modal_domain.config_domain')"
						/>
						<TextPlay
							class="desc"
							color="var(--text-color)"
							size="sm"
							:text="$t('modal_domain.config_domain_desc')"
						/>

						<InputFormPlay
							class="create-domain__input"
							v-model="newDomain"
							:validation="{required: true}"
							:label="$t('modal_domain.url_domain')"
							:placeholder="$t('modal_domain.placeholder_domain')"
						/>

						<ButtonPlay
							@click="createDomain"
							type="normal"
							h="48px"
							w="100%"
							:text="$t('modal_domain.save')"
						/>
					</div>
					<div
						class="created-domain"
						v-if="pageDomain === 2"
					>
						<TextPlay
							class="title"
							color="var(--fontcolor)"
							weight="semibold"
							:text="$t('modal_domain.config_domain')"
						/>
						<TextPlay
							class="desc"
							color="var(--text-color)"
							size="sm"
							:text="$t('modal_domain.saved_domain_desc')"
						/>

						<div class="created-domain__bullets">
							<div class="bullet">
								<TextPlay
									color="var(--fontcolor)"
									weight="semibold"
									size="sm"
									v-html="1"
								/>
								<TextPlay
									color="var(--text-color)"
									size="sm"
									v-html="$t('modal_domain.step_one')"
								/>
							</div>
							<div class="bullet">
								<TextPlay
									color="var(--fontcolor)"
									weight="semibold"
									size="sm"
									v-html="2"
								/>
								<TextPlay
									color="var(--text-color)"
									size="sm"
									v-html="$t('modal_domain.step_two')"
								/>
							</div>
							<div class="bullet">
								<TextPlay
									color="var(--fontcolor)"
									weight="semibold"
									size="sm"
									v-html="3"
								/>
								<TextPlay
									color="var(--text-color)"
									size="sm"
									v-html="$t('modal_domain.step_three')"
								/>
							</div>
							<div class="bullet">
								<TextPlay
									color="var(--fontcolor)"
									weight="semibold"
									size="sm"
									v-html="4"
								/>
								<TextPlay
									color="var(--text-color)"
									size="sm"
									v-html="$t('modal_domain.step_four')"
								/>
							</div>
						</div>

						<ButtonPlay
							@click="openModalDominio()"
							v-b-toggle="id"
							type="normal"
							h="48px"
							w="100%"
							:text="$t('modal_domain.conclude')"
						/>
					</div>
				</div>
			</div>
		</b-sidebar>
	</portal>
</template>

<script>
import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();

export default {
	data() {
		return {
			newDomain: "",
			pageDomain: 1,
			url_domain: process.env.VUE_URL_DOMAIN,
		};
	},
	props: {
		id: {
			type: String,
			default: "domain-sidebar",
		},
	},
	methods: {
		openModalDominio() {
			this.$root.$emit("bv::show::modal", "modal-dominio", "#btnShow");
		},
		async createDomain() {
			var data = {
				domain: this.newDomain,
			};

			this.$root.$emit("loadOn");

			try {
				await serviceDomain.create(data);
				this.pageDomain = 2;

				this.$bvToast.toast(this.$t("settings.updated_with_success"), {
					title: this.$t("settings.updated"),
					variant: "success",
					autoHideDelay: 5000,
					appendToast: true,
				});
			} catch (error) {
				this.$bvToast.toast(this.$t("settings.updated_with_error"), {
					title: this.$t("settings.error_to_update"),
					variant: "danger",
					solid: true,
					autoHideDelay: 5000,
				});
			} finally {
				this.$root.$emit("loadOff");
			}
		},
	},
};
</script>

<style lang="scss">
.vue-portal-target {
	.b-sidebar-outer {
		z-index: 3016;
	}

	.b-sidebar {
		padding: 40px !important;
	}
}
::-webkit-scrollbar {
    display: none;
}
.domain-sidebar-container {
	background-color: var(--backgroundcolor) !important;
}

.domain-sidebar {
	padding-right: 4px;
	&__header {
		display: flex;
		justify-content: flex-end;

		.isax {
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	&__body {
		.create-domain {
			.title {
				margin-bottom: 8px;
			}

			&__input {
				margin: 24px 0;
			}
		}

		.created-domain {
			.title {
				margin-bottom: 8px;
			}

			.desc {
				margin-bottom: 32px;
			}

			&__bullets {
				display: flex;
				flex-direction: column;
				margin-bottom: 32px;

				.bullet {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 16px;
					padding: 24px 0;
					border-bottom: 1px solid var(--bordercolor);

					&:nth-child(1) {
						padding-top: 0;
					}

					&:nth-child(4) {
						border-bottom: none;
						padding-bottom: 0;
					}
				}
			}
		}
	}
}
</style>
