<template>
	<div
		@click="$emit('click')"
		:style="computedStyle"
		class="image-picker-play"
	>
		<div
			v-if="!src"
			class="image-picker-play__unselected"
			:class="{ 'image-picker-play__unselected--horizontal': horizontal }"
		>
			<IconSax
				name="gallery-add"
				color="var(--fontcolor)"
				size="24px"
			/>
			<TextPlay
				:text="$t('image_picker.add_image')"
				color="var(--fontcolor)"
				size="xs"
			/>
		</div>
		<div
			class="image-picker-play__selected"
			v-if="src"
		>
			<img
				:src="src"
				:alt="$t('image_picker.selected_image')"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
		},
		h: {
			type: String,
			default: "100px",
		},
		w: {
			type: String,
			default: "100%",
		},
		horizontal: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedStyle() {
			return {
				height: this.h,
				width: this.w,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.image-picker-play {
	border-radius: 8px;
	background-color: var(--image-picker-bg);
	border: 2px dashed var(--image-picker-border-color);
	cursor: pointer;

	&__unselected {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		gap: 8px;
		text-align: center;

		&--horizontal {
			flex-direction: row;
		}
	}

	&__selected {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		padding: 16px;

		img {
			border-radius: 8px;
			height: 100%;
			width: 100%;
			object-fit: scale-down;
		}
	}
}
</style>
