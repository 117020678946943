<template>
  <div class="flex-row flex-1 flex-wrap mb-2 gap-1">
    <div class="flex-row flex-1 gap-1 items-start">
      <InputIcon v-model="icon" />
      <b-form-input v-model="label" type="text" placeholder="Nome do link" class="input-name" />
    </div>
    <div class="flex-row flex-1 gap-1 items-start">
      <div class="flex-1">
        <b-form-input v-model="url" type="text" placeholder="URL" class="input-url" />
        <b-form-checkbox v-model="newtab" name="newtap" value="1">
          Abrir link em nova aba
        </b-form-checkbox>
      </div>
      <ButtonPlay @click="save" class="input-submit" style="cursor: pointer; text-decoration: underline">
        Salvar
      </ButtonPlay>
    </div>
  </div>
</template>
<script>
import ButtonPlay from "@/components/common/ButtonPlay"
import InputIcon from "@/components/form/InputIcon"
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ButtonPlay,
    InputIcon,
  },
  props: ['index'],
  data() {
    return {
      icon: "",
      label: "",
      url: "",
      newtab: false
    }
  },
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings",
    })
  },
  methods: {
    ...mapActions({
      actionSaveSettings: 'config/actionSaveSettings'
    }),
    async save() {
      await Promise.all([
        this.actionSaveSettings({
          id: `link_menu_${this.index}_newtab`,
          value: this.newtab
        }),
        this.actionSaveSettings({
          id: `link_menu_${this.index}_url`,
          value: this.url
        }),
        this.actionSaveSettings({
          id: `link_menu_${this.index}_label`,
          value: this.label
        }),
        this.actionSaveSettings({
          id: `link_menu_${this.index}_icon`,
          value: this.icon
        })
      ])
    },
    load() {
      this.icon = this.getSettings[`link_menu_${this.index}_icon`]
      this.label = this.getSettings[`link_menu_${this.index}_label`]
      this.url = this.getSettings[`link_menu_${this.index}_url`]
      this.newtab = this.getSettings[`link_menu_${this.index}_newtab`]
    }
  },
  mounted() {
    this.load()
  }
}

</script>
<style lang="scss" scoped>
@mixin rounded-input {
  height: 40px;
  border: 1px solid #ededf0 !important;
  border-radius: 30px;
  font-weight: 400;
  font-size: 13px;
  color: var(--fontcolor);
}

.input-name {
  @include rounded-input;
  min-width: 13rem;
  flex: 1;
}

.input-url {
  @include rounded-input;
  min-width: 20rem;
  flex: 1;
}

.input-submit {
  width: 8rem;
}
</style>
